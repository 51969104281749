import React from "react"
import styles from "./MakeItReal.module.css"
import MakeItRealItem from "./MakeItRealItem.js"
import passion from "../images/passion.svg"
import expertise from "../images/expertise.svg"
import future from "../images/future.svg"

const MakeItReal = () => (
  <div className={styles.container}>
    <div className={styles.backgroundDetails1} />
    <div className={styles.backgroundDetails2} />
    <div className={styles.backgroundDetails3} />
    <div className={styles.content}>
      <h2 className={styles.title}>
        {"Let's make "}
        <br />
        it <span>real.</span>
      </h2>
      <div className={styles.contentContainer}>
        <MakeItRealItem
          title="Passion"
          illustrationName={passion}
          copy="We work with businesses who are passionate, committed and aren't afraid to get their hands dirty."
        />
        <MakeItRealItem
          title="Expertise"
          illustrationName={expertise}
          copy="We want to disrupt industries by combining your expertise in the target market with our knowledge of building products."
        />
        <MakeItRealItem
          title="Future Proof"
          illustrationName={future}
          copy="We use popular industry standard tech to facilitate recruitment, allowing your new team to easily take the product forward beyond initial launch."
        />
      </div>
    </div>
  </div>
)

export default MakeItReal
