import React from "react"
import styles from "./clients.module.css"
import ferly from "../../../static/ferly.png"
import growyze from "../../../static/growyze.png"
import ible from "../../../static/ible.png"
import wild from "../../../static/wildbytart.png"
import motley from "../../../static/motley.png"
import takeplace from "../../../static/takeplace.png"

const Clients = () => {
  return (
    <>
      {/* <div className={styles.line}></div> */}
      <h2 className={styles.clientTitle}>
        {" "}
        WE'VE HAD THE PLEASURE OF WORKING WITH
      </h2>

      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <div className={styles.logoBox}>
            <img className={styles.logo} src={growyze} alt="growyze" />
            <img className={styles.logo} src={ferly} alt="ferly" />
            <img className={styles.logo} src={ible} alt="ibl" />
            <img className={styles.logo} src={wild} alt="wild" />
            <img className={styles.logo} src={motley} alt="motley" />
            <img className={styles.logo} src={takeplace} alt="tp" />
          </div>
          <div className={styles.logoBoxMobile}>
            <img className={styles.logo} src={growyze} alt="growyze" />
            <img className={styles.logo} src={ferly} alt="ferly" />
            <img className={styles.logo} src={ible} alt="ibl" />
            <img className={styles.logo} src={wild} alt="wild" />
            <img className={styles.logo} src={motley} alt="motley" />
            <img className={styles.logo} src={takeplace} alt="tp" />
          </div>
        </div>
      </div>

      {/* <div className={styles.line}></div> */}
    </>
  )
}

export default Clients
