import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import styles from './AnimatedText.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);



class AnimatedText extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	}

	componentDidMount() {
		const randomTime = this.props.delay + (this.props.speed * 400);
		setTimeout(()=>{
			this.setState(()=>({
				show: true
			}));

			if (this.props.dissapear) {
				setTimeout(()=>{
					this.setState(()=>({
						hide: true
					}));
				}, 1400);

			}

		}, randomTime);
	}

	render() {


		return (
			<span className={this.props.className}>

				<span className={cx({
					letterContainer: true,
					display: this.state.show,
					hide: this.state.hide,
					quickAnimation: this.props.quick
				})}>
					<span className={ styles.letterContent} >
						{this.props.children}
					</span>
				</span>
			</span>

		);
	}
}

AnimatedText.propTypes = {
	className: PropTypes.string,
	dissapear: PropTypes.bool,
	speed: PropTypes.number,
	children:PropTypes.node,
	delay: PropTypes.number,
	quick: PropTypes.bool
};

AnimatedText.defaultProps = {
	delay: 0
};

export default AnimatedText;