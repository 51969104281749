import React from 'react';
import styles from './MakeItReal.module.css';
import PropTypes from 'prop-types';

const MakeItRealItem = ({title, illustrationName, copy}) => (
	<div className={styles.item}>
		<img className={styles.illustration} src={illustrationName} alt={`${title} Illustration`} />
		<h3 className={styles.itemTitle}>{title}</h3>
		<p className={styles.itemContent}>{copy}</p>
	</div>
);

MakeItRealItem.propTypes = {
	title: PropTypes.string.isRequired,
	illustrationName: PropTypes.string.isRequired,
	copy: PropTypes.string.isRequired
};


export default MakeItRealItem;
